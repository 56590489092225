// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import Footer from '../../../TeaserFooter/TeaserFooter';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import AdSlot from '../../../AdManager/AdSlot';
import useOneTime from '../../../../hooks/useOneTime';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

import type { attrFlowType, } from '../../../../flowTypes/attrTypes';


Calculon.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
  hasMarginTop: false,
};

const teasers = [ Teaser1, Teaser2, Teaser3, Teaser4, Teaser5, ];
const areasTemplate = [
  { until: 's', value: '"he" "t1" "t2" "t3" "t4" "t5"', },
  {
    from: 's',
    until: 'l',
    value: `
    "he he he he he he"
    "t1 t1 t1 t1 t2 t2"
    "t3 t3 t3 t3 t3 t3"
    "t4 t4 t4 t5 t5 t5"
    `,
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he t1 t1"
    "he t2 t3"
    "he t4 t5"
    `,
  },
  {
    from: 'xl',
    value: `
    "he t1 t1 t2"
    "he t1 t1 t2"
    "he t3 t4 t5"
    `,
  },
];

const isValidItem = item => item && item.representedContent !== 'UNDEFINED.UNDEFINED';

export default function Calculon({
  list,
  isLazyloadImages,
  biAction,
  biImpression,
  gaAction,
  hasMarginTop,
}: ListViewProps & { hasMarginTop?: boolean, }): React.Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, extraLinks, dfp, rainbowTargetSlot, ...restOfList } = list || {};
  const validItems = items ? items.filter(isValidItem) : null;

  if (!(validItems && validItems.length)) return null;

  const dfpItem = dfp && dfp[0];
  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        biImpression: setBiImpression(i, data, biImpression),
        isLazyloadImages,
        theme,
        attrs: isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {},
      });
    }
    return null;
  }

  return (
    <React.Fragment>
      <ListView
        areasTemplate={areasTemplate}
        colTemplate={[
          { from: 's', until: 'l', value: '1fr 1fr 1fr 1fr 1fr 1fr', },
          { from: 'l', until: 'xl', value: '2fr 5fr 5fr', },
          { from: 'xl', value: '2fr 4fr 4fr 2fr', },
        ]}
        {...(hasMarginTop ? { marginTop: [ { from: 'l', value: '0', }, ], } : {})}
        attrs={{
          'data-test': 'calculon',
        }}
      >
        {restOfList.title || restOfList.commercialLinks?.length || extraLinks?.length ? (
          <ListViewHeader
            {...restOfList}
            extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
            biAction={biAction}
            isSticky
          />
        ) : null}
        { rainbowTargetSlot
          ? <RainbowListPaywallSlot id={rainbowTargetSlot} gridArea="t1 / t1 / t5 /t5" onToolRendered={onRainbowToolRendered} />
          : null}
        <Section isFragment>{validItems.map(itemRenderer)}</Section>
      </ListView>
      {dfpItem && (
        <AdSlot
          {...dfpItem}
          loadPriority={list.loadPriority}
          wrapperMiscStyles={{
            marginTop: [ { from: 'l', value: '8rem', }, ],
            width: '100%',
          }}
        />
      )}
    </React.Fragment>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Components                          //
// /////////////////////////////////////////////////////////////////////

const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
  biImpression: null,
  attrs: null,
};

const areasMediaObjTeaser = `
"media content"
"media footer"
`;
const areasStackedTeaser = `
"media"
"content"
"footer"
`;
const stackedTeaserRow = 'auto 1fr auto';
const mediaObjTeaserRow = '1fr auto';

type TeaserPropsType = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  biImpression: ?() => void,
  theme: Object,
  attrs?: ?attrFlowType,
};


Teaser1.defaultProps = teaserDefaultProps;
function Teaser1({
  data,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserPropsType): React.Node {
  const { calculonTeasers, } = theme.calculonStyle;

  const image = data?.mobileImage || data?.image;

  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });

  return (
    <Teaser
      fwRef={ref}
      key={data.contentId}
      gridArea="t1"
      areasTemplate={[
        { until: 'l', value: areasStackedTeaser, },
        { from: 'l', value: areasMediaObjTeaser, },
      ]}
      colTemplate={[
        { from: 'l', until: 'xl', value: '7fr 3fr', },
        { from: 'xl', value: '6fr 2fr', },
      ]}
      rowTemplate={[
        { until: 'l', value: stackedTeaserRow, },
        { from: 'l', value: mediaObjTeaserRow, },
      ]}
      rowGap="1rem"
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          image={image}
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'headline',
            sizes: [
              { from: 'xl', size: '597px', },
              { from: 'l', size: '567px', },
              { from: 'm', size: '472px', },
              { from: 's', size: '360px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 597, 567, 472, 360, 400, ],
          })}
          onClick={biAction}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={calculonTeasers.teaser1.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={calculonTeasers.teaser1.headerWrapperMiscStyles}
      />
      <Footer
        data={data}
        showRank
        showAuthor
        showTime={calculonTeasers.teaser1.footer.showTime}
        showCommentsCount={calculonTeasers.teaser1.footer.showCommentsCount}
        miscStyles={calculonTeasers.teaser1.footer.miscStyles}
      />
    </Teaser>
  );
}

Teaser2.defaultProps = teaserDefaultProps;
function Teaser2({
  data,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserPropsType): React.Node {
  const horizontalPadding = {
    paddingInlineEnd: '1rem',
    paddingInlineStart: [
      { from: 's', until: 'l', value: '1rem', },
      { from: 'xl', value: '1rem', },
    ],
  };

  const { calculonTeasers, } = theme.calculonStyle;

  const image = data?.mobileImage || data?.image;

  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });

  return (
    <Teaser
      fwRef={ref}
      key={data.contentId}
      gridArea="t2"
      areasTemplate={[
        { until: 's', value: areasMediaObjTeaser, },
        { from: 's', until: 'l', value: areasStackedTeaser, },
        { from: 'l', until: 'xl', value: areasMediaObjTeaser, },
        { from: 'xl', value: areasStackedTeaser, },
      ]}
      colTemplate={[
        { until: 's', value: '18rem 1fr', },
        { from: 'l', until: 'xl', value: '2fr 3fr', },
      ]}
      rowTemplate={[
        { until: 's', value: mediaObjTeaserRow, },
        { from: 's', until: 'l', value: stackedTeaserRow, },
        { from: 'l', until: 'xl', value: mediaObjTeaserRow, },
        { from: 'xl', value: stackedTeaserRow, },
      ]}
      colGap="1rem"
      rowGap="1rem"
      gridGap={null}
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: [
                { from: 'l', size: '154px', },
                { from: 'm', size: '224px', },
                { from: 's', size: '168px', },
                { size: '108px', },
              ],
              aspect: 'square',
              widths: [ 108, 154, 168, 204, 224, 400, ],
            },
            sources: [
              {
                aspect: calculonTeasers.teaser2.pictureSourceAspect,
                from: 'xl',
                sizes: '188px',
                widths: [ 188, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={calculonTeasers.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          ...horizontalPadding,
          paddingTop: [
            { until: 's', value: '1rem', },
            { from: 'l', until: 'xl', value: '1rem', },
          ],
          ...calculonTeasers.teaser2.headerWrapperMiscStyles,
        }}
      />
      <Footer
        data={data}
        showAuthor={calculonTeasers.footer.showAuthor}
        showCommentsCount={calculonTeasers.footer.showCommentsCount}
        showTime={false}
        miscStyles={{ ...horizontalPadding,
          paddingBottom: '1rem',
          ...calculonTeasers.teaser2.footerMiscStyles,
        }}
      />
    </Teaser>
  );
}

Teaser3.defaultProps = teaserDefaultProps;
function Teaser3({
  data,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserPropsType): React.Node {
  const { calculonTeasers, } = theme.calculonStyle;

  const image = data?.mobileImage || data?.image;

  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });
  return (
    <Teaser
      fwRef={ref}
      key={data.contentId}
      gridArea="t3"
      areasTemplate={areasMediaObjTeaser}
      colTemplate={[
        { until: 's', value: '18rem 1fr', },
        { from: 's', until: 'l', value: '1fr 2fr', },
        { from: 'l', until: 'xl', value: '2fr 3fr', },
        { from: 'xl', value: '2fr 2fr', },
      ]}
      rowTemplate={mediaObjTeaserRow}
      colGap="1rem"
      rowGap={calculonTeasers.teaser3.rowGap}
      gridGap={null}
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: [
                { from: 'l', until: 'xl', size: '154px', },
                { size: '108px', },
              ],
              aspect: 'square',
              widths: [ 108, 154, 216, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 'xl',
                sizes: '189px',
                widths: [ 189, ],
              },
              {
                aspect: 'regular',
                from: 's',
                until: 'l',
                sizes: [
                  { from: 'm', until: 'l', size: '238px', },
                  { from: 's', until: 'm', size: '182px', },
                ],
                widths: [ 182, 376, 238, 512, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={calculonTeasers.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{ paddingTop: '1rem',
          paddingInlineEnd: '1rem',
          ...calculonTeasers.teaser3.teaserHeaderMiscStyles, }}
      />
      <Footer
        data={data}
        showAuthor={calculonTeasers.footer.showAuthor}
        showCommentsCount={calculonTeasers.footer.showCommentsCount}
        showTime={false}
        miscStyles={{ paddingBottom: '1rem',
          paddingInlineEnd: '1rem',
          ...calculonTeasers.teaser3.footerMiscStyles, }}
      />
    </Teaser>
  );
}

Teaser4.defaultProps = teaserDefaultProps;
function Teaser4({
  data,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  attrs,
}: TeaserPropsType): React.Node {
  const { calculonTeasers, } = theme.calculonStyle;
  const image = data?.mobileImage || data?.image;

  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });


  return (
    <Teaser
      fwRef={ref}
      key={data.contentId}
      gridArea="t4"
      areasTemplate={areasMediaObjTeaser}
      colTemplate={[
        { until: 'xl', value: '0 1fr', },
        { from: 'xl', value: '2fr 2fr', },
      ]}
      rowTemplate={mediaObjTeaserRow}
      colGap="1rem"
      rowGap={calculonTeasers.teaser4.rowGap}
      gridGap={null}
      attrs={attrs}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          image={image}
          lazyLoad={isLazyloadImages}
          imgOptions={{ transforms: { aspect: 'regular', width: 189, }, }}
          miscStyles={{ display: [ { until: 'xl', value: 'none', }, ], }}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={calculonTeasers.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          paddingInlineEnd: '1rem',
          paddingInlineStart: [ { until: 'xl', value: '1rem', }, ],
          paddingTop: '1rem',
          ...calculonTeasers.teaser4.headerWrapperMiscStyles,
        }}
      />
      <Footer
        data={data}
        showAuthor={calculonTeasers.footer.showAuthor}
        showCommentsCount={calculonTeasers.footer.showCommentsCount}
        showTime={false}
        miscStyles={{
          paddingBottom: '1rem',
          paddingInlineEnd: '1rem',
          paddingInlineStart: [ { until: 'xl', value: '1rem', }, ],
          ...calculonTeasers.teaser4.footerMiscStyles,
        }}
      />
    </Teaser>
  );
}

Teaser5.defaultProps = teaserDefaultProps;
function Teaser5({ data, biAction, biImpression, theme, attrs, }: TeaserPropsType): React.Node {
  const { calculonTeasers, } = theme.calculonStyle;

  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  useOneTime(!!inView && !!biImpression, () => {
    !!biImpression && biImpression();
  });

  return (
    <Teaser
      fwRef={ref}
      key={data.contentId}
      gridArea="t5"
      rowTemplate="1fr auto"
      rowGap={calculonTeasers.teaser5.rowGap}
      areasTemplate="'content' 'footer'"
      backgroundColor={[ 'neutral', '-2', ]}
      attrs={attrs}
    >
      <TeaserHeader
        isGridItem
        kickerMiscStyles={{ color: theme.color('calculonList', 't5Kicker'), }}
        typeScale={calculonTeasers.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={calculonTeasers.teaser5.headerWrapperMiscStyles}
        miscStyles={{ color: theme.color('white'), }}
      />
      <Footer
        isDark
        data={data}
        showAuthor={calculonTeasers.footer.showAuthor}
        showCommentsCount={calculonTeasers.footer.showCommentsCount}
        showTime={false}
        commentsColor={[ 'calculonList', 't5FooterColor', ]}
        miscStyles={{
          color: theme.color('calculonList', 't5FooterColor'),
          ...calculonTeasers.footer.miscStyles,
        }}
        tickerMiscStyles={isPositive => ({
          color: isPositive
            ? theme.color('positive', '-2')
            : theme.color('negative', '-2'),
          ':visited': {
            color: isPositive
              ? theme.color('positive', '-2')
              : theme.color('negative', '-2'),
          },
        })}
      />
    </Teaser>
  );
}
