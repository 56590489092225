// @flow
import * as React from 'react';
import { BARBADOS_LIST_QUERY, } from '@haaretz/graphql';
import ListWrapper from '../../ListWrapper';
import BarbadosView from './BarbadosView';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type BarbadosWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BarbadosWrapper(props: BarbadosWrapperProps) {
  return (
    <ListWrapper {...props} query={BARBADOS_LIST_QUERY} view="Barbados">
      {dataProps => <BarbadosView {...dataProps} />}
    </ListWrapper>
  );
}
