// @flow
/* global window */
import React from 'react';
import { CALCULON_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import CalculonView from './CalculonView.js';

type CalculonWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
  viewProps: Object,
}

export default function CalculonWrapper(props: CalculonWrapperProps) {
  const hasMarginTop = props.viewProps && props.viewProps.hasMarginTop;
  return (
    <ListWrapper {...props} query={CALCULON_LIST_QUERY} view="Calculon">
      {dataProps => <CalculonView {...dataProps} hasMarginTop={hasMarginTop} />}
    </ListWrapper>
  );
}
