// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import Section from '../../../AutoLevels/Section';
import Picture from '../../../Image/Picture';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Teaser from '../../../Teaser/NewTeaser';
import Footer from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import useMedia from '../../../../hooks/useMedia';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import useOneTime from '../../../../hooks/useOneTime';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';


Barbados.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teasers = [ Teaser1, Teaser1, Teaser2, Teaser2, Teaser2, ];
const teasersMobileHdc = [ Teaser1, Teaser2, Teaser2, Teaser2, Teaser2, ];

export default function Barbados({
  list,
  isLazyloadImages,
  biAction,
  biImpression,
  inView,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, extraLinks, rainbowTargetSlot, ...restOfList } = list || {};
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: true, });

  useOneTime(items && !!biImpression && typeof biImpression === 'function' && !!inView, () => items.map((item, i) => {
    i < 4 && biImpression && typeof setBiImpression === 'function' && setBiImpression(i, item, biImpression)();
    return null;
  }));

  if (!(items && items.length)) return null;

  return (
    <ListView
      areasTemplate={theme.barbadosStyle.listView.areaTemplate}
      colTemplate={[
        { until: 's', value: '1fr', },
        { from: 's', until: 'l', value: '1fr 1fr', },
        { from: 'l', value: '1fr 1fr 2fr', },
      ]}
      gridGap={null}
      rowGap={theme.barbadosStyle.listView.rowGap}
      colGap="4rem"
      sectionMiscStyles={{
        marginTop: '0',
      }}
      innerBackgroundColor={[ { from: 's', value: 'white', }, ]}
      padding={theme.barbadosStyle.listView.padding}
      attrs={{
        'data-test': 'barbados',
      }}
    >
      {rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea="t1 / t1 / t5 / t5"
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      {theme.barbadosStyle.displayListViewHeader ? (
        <ListViewHeader
          {...restOfList}
          width={1}
          title={list && list.title}
          biAction={biAction}
          isSticky
          isHorizontal
        />
      )
        : null}
      <Section isFragment>
        {items.map((data, i) => {
          if (data && teasers[i]) {
            const TeaserItem = theme.barbadosStyle.displayTeasersMobileHdc(isMobile) ? teasersMobileHdc[i] : teasers[i];
            const gridArea = `t${i + 1}`;
            return (
              <TeaserItem
                key={data.contentId}
                gridArea={gridArea}
                data={data}
                biAction={setBiAction(i, data, biAction)}
                isLazyloadImages={isLazyloadImages}
                theme={theme}
                attrs={isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {}}
              />
            );
          }
          return null;
        })}

      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Components                          //
// /////////////////////////////////////////////////////////////////////

const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const areasMediaObjTeaser = `
"media content"
"media footer"
`;

const areasMediaObjTeaserSizeS = `
"media content"
"media ."
"media footer"
`;
const areasStackedTeaser = `
"media"
"content"
"footer"
`;

const stackedTeaserRow = 'auto 1fr auto';
const mediaObjTeaserRow = 'auto 1fr';
const mediaObjTeaserRowSizeS = 'auto 1fr auto';

type TeaserPropsType = {
  gridArea: string,
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  theme: Object,
};

Teaser1.defaultProps = teaserDefaultProps;
function Teaser1({
  data,
  isLazyloadImages,
  biAction,
  theme,
  gridArea,
}: TeaserPropsType): React.Node {
  const { teaser1, } = theme.barbadosStyle;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={areasStackedTeaser}
      rowTemplate={stackedTeaserRow}
      rowGap={teaser1.rowGap}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: 'calc(100vw - 24px)',
              aspect: 'headline',
              widths: [ 570, 390, 351, 296, ],
            },
            sources: [
              {
                aspect: 'square',
                from: 's',
                sizes: [
                  { from: 'xl', size: '288px', },
                  { from: 'l', size: '232px', },
                  { from: 'm', size: '348px', },
                  { size: '264px', },
                ],
                widths: [ 348, 288, 232, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={teaser1.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={{
          padding: [ { until: 's', value: '0 1rem', }, ],
        }}
      />
      <Footer
        data={data}
        showRank
        showAuthor
        showCommentsCount={teaser1.footer.showCommentsCount}
        miscStyles={{ padding: [ { until: 's', value: '1rem', }, ], }}
        showLive={!!data.liveUpdates}
      />
    </Teaser>
  );
}

Teaser2.defaultProps = teaserDefaultProps;
function Teaser2({
  data,
  isLazyloadImages,
  biAction,
  theme,
  gridArea,
}: TeaserPropsType): React.Node {
  const { teaser2, } = theme.barbadosStyle;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={[
        { until: 's', value: areasMediaObjTeaserSizeS, },
        { from: 's', value: areasMediaObjTeaser, },
      ]}
      colTemplate={[
        { until: 's', value: '17rem 1fr', },
        { from: 's', until: 'l', value: '9fr 29fr', },
        { from: 'l', until: 'xl', value: '13fr 29fr', },
        { from: 'xl', value: '14fr 30fr', },
      ]}
      rowTemplate={[
        { until: 's', value: mediaObjTeaserRowSizeS, },
        { from: 's', value: mediaObjTeaserRow, },
      ]}
      colGap={teaser2.colGap}
      rowGap={teaser2.rowGap}
      gridGap={null}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              sizes: '102px',
              aspect: 'square',
              widths: [ 102, 204, ],
            },
            sources: [
              {
                aspect: 'headline',
                from: 's',
                sizes: [
                  { from: 'xl', size: '181px', },
                  { from: 'l', size: '142px', },
                  { from: 'm', size: '170px', },
                  { size: '130px', },
                ],
                widths: [ 181, 142, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        typeScale={teaser2.headerTypeScale}
        {...data}
        onClick={biAction}
        wrapperMiscStyles={teaser2.headerWrapperMiscStyles}
      />
      <Footer
        data={data}
        showRank
        showAuthor
        showCommentsCount={teaser2.footer.showCommentsCount}
        miscStyles={{ padding: [ { until: 's', value: '1rem', }, ], }}
      />
    </Teaser>
  );
}
